<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field memberNameNumber">
            <div class="title">회원명/번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="waitingReservationSearchOption.memberNameNumber"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
        <!--
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref='shortcutMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='shortcutMenuItems'>
              바로가기
            </ejs-dropdownbutton>
          </li>
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
        </ul>
        -->
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">대기예약 목록</div>
                <div class="header-caption">[{{ count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button
                        button-div="SAVE"
                        :ignore="isPopupOpened"
                        :is-icon-custom="true"
                        :is-custom-shortcut-button="true"
                        shortcut-key="waitingReservation.shortcuts.add"
                        :shortcut="{key: 'F3'}"
                        @click.native="waitingReservationInfoPopupOpen"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li class="cancel">
                    <erp-button
                        button-div="DELETE"
                        :is-icon-custom="true"
                        @click.native="waitingReservationCancelButtonClicked"
                    >
                      접수 취소
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onClickExcel"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="waitingReservationGrid"
                :provides="waitingReservationGridOptions.provides"
                :columns="waitingReservationGridOptions.columns"
                :dataSource="waitingReservationList"
                :allowPaging="true"
                :allowResizing="true"
                :allowGrouping="true"
                :isNOColumnDisplay="false"
                :allowExcelExport="true"
                :groupSettings="waitingReservationGridOptions.groupSettings"
                :editSettings="waitingReservationGridOptions.editSettings"
                :selectionSettings="waitingReservationGridOptions.selectionSettings"
                :aggregates="waitingReservationGridOptions.aggregates"
                :isAutoSelectRow="false"
                :isAutoSelectCell="false"
                :isSelectedRowRetain="false"
                :pageSettings="{ pageSize: 50 }"
                @headerCellInfo="waitingReservationHeaderCellInfo"
                @queryCellInfo="waitingReservationQueryCellInfo"
                @recordClick="waitingReservationGridRecordClicked"
                @rowSelected="waitingReservationGridRowSelected"
                @dataBound="dataBoundEvent"
                @actionComplete="waitingReservationGridActionComplete"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">타임 목록</div>
                <div class="header-caption">
                  <!-- 토요일 : saturday / 일요일 : sunday -->
                  [{{ timeCount.resveDate }}
                  <span class="saturday">{{ timeCount.dwName }}</span> 잔여
                  <strong>{{ timeCount.resvePossibleTime }}</strong
                  >팀]
                </div>
              </div>
              <div class="header-right">
                <ul class="header-check">
                  <li>
                    <label>
                      <input type="checkbox" v-model="smsSendFlag" />
                      <i></i>
                      <div class="label">SMS전송</div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="timeListGrid"
                :provides="timeListGridOptions.provides"
                :columns="timeListGridOptions.columns"
                :dataSource="timeList"
                :allowResizing="true"
                :editSettings="timeListGridOptions.editSettings"
                @headerCellInfo="waitingReservationHeaderCellInfo"
                @queryCellInfo="timeListQueryCellInfo"
                @recordClick="timeListGridRecordClicked"
                @actionComplete="onTimeListGridActionComplete"
              />
            </div>
          </section>
        </div>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-waitReservationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">영업 구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="bsnCodeDropdown"
                        v-model="waitingReservationSearchOption.bsnCode"
                        :dataSource="
                          waitingReservationSearchOption.bsnCodeOptions
                        "
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">대기일자</div>
                  <ul class="content">
                    <li class="item dateRange">
                      <input-date-range
                        v-model="searchDateInputDateRange"
                        type="lookupDetail-condition"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">접수 상태</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="rceptStatusDropdown"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="waitingReservationSearchOption.rceptStatus"
                        :dataSource="
                          waitingReservationSearchOption.rceptStatusOptions
                        "
                        :fields="{ text: 'comName', value: 'comCode' }"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회원명</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="waitingReservationSearchOption.memberName"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회원번호</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="waitingReservationSearchOption.memberNumber"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  v-on:click.native="onSearchDetailViewButtonClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onDialogWaitingReservationOptionInitClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="searchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <waiting-reservation-popup
      v-if="isWaitingReservationPopupOpen"
      ref="waitingReservationPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      @popupEvent="onWaitingReservationPopupEvent"
    />
    <blacklist-popup
      v-if="isBlacklistPopupOpen"
      ref="blacklistPopup"
      @popupClosed="onBlacklistPopupClose"
    />
    <reservation-info-popup
      v-if="isReservationInfoPopupOpen"
      ref="reservationInfoPopup"
      @popupClosed="onReservationInfoPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.memberNameNumber .content .item{width:120px}
body .appContent .body-article .article-left{width:calc(100% - 347px)}
body .appContent .body-article .article-right{width:347px}
body .appContent .body-article .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-02 .header-caption .saturday{color:#2f55f8}
body .appContent .article-section.section-02 .header-caption .sunday{color:#f82f2f;color:#f82f2f}
body .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#2e7458!important}
html body.theme-red .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#c03737!important}
html body.theme-orange .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#d86632!important}
html body.theme-yellow .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#e2a429!important}
html body.theme-green .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#2e7458!important}
html body.theme-blue .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#3170b3!important}
html body.theme-indigo .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#5a3dac!important}
html body.theme-purple .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#893bab!important}
html body.theme-black .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#444!important}
</style>

<script>
import Vue from "vue";
import {
  Aggregate,
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
} from "@syncfusion/ej2-vue-grids";

import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetStandardInfo,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodeGetComCodeDefaultValue, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import {getFormattedDate, getDayOfWeekCaption, getTodayNavigationDate, getDayColorValue} from '@/utils/date';
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {
  getWaitingReservation,
  saveReservationWaitCancel,
  getReservationWaitTimeList,
} from "@/api/waitingReservation";

import WaitingReservationPopup from "./popup/WaitingReservationPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import reservationInfoPopup from "@/views/common/ReservationInfoPopup";
import BlacklistPopup from '@/views/golf-reservation/popup/BlacklistPopup';
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
} from "@/utils/gridUtil";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import moment from "moment";

import GolfErpAPI from "@/api/v2/GolfErpAPI";

import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "waitingReservationRegistration",
  components: {
    InputText,
    WaitingReservationPopup,
    EjsGridWrapper,
    InputDateRange,
    BlacklistPopup,
    reservationInfoPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      count: 0,
      waitingReservationListLength: 0,
      reSelectionWaitingId: null,
      timeList: [],
      timeCount: {
          resveDate: "",
          timeCount: 0,
          resvePossibleTime: 0,
          dwCode: "",
          dwName: "",
      },
      isBlacklistPopupOpen: false,
      isReservationInfoPopupOpen: false,
      isSearchDetailPopupOpen: false,
      isWaitingReservationPopupOpen: false,
      waitingReservationList: [],
      reservationWaits: {
        waitingId: null,
        memberName: null,
        memberNo: null,
        memberGrade: null,
        sexCode: null,
        areaCode: null,
        waitingDate: null,
        hopeTime1: null,
        hopeTime2: null,
        hopeCourse: null,
        hopeHoleDiv: null,
        hopeTeamCnt: null,
        selfFlag: false,
        requstContents: null,
        rceptStatus: null,
        resveTime: "07:00",
        resveCourse: "OUT",
        insertId: "전산 관리자",
        insertDt: "2019-01-01 09:00",
      },
      waitingReservationSearchOption: {
        memberNameNumber: null,
        bsnCode: null,
        bsnCodeOptions: [],
        waitingDateFrom: null,
        waitingDateTo: null,
        rceptStatus: [],
        rceptStatusOptions: [],
        hopeTimeOptions: [],
        memberName: null,
        memberNumber: null,
        resveCourseOptions: [],
        hopeCourseOptions: [],
        memberDivOptions: [],
      },
      timeListOption: {
        resveCourseOptions: [],
        memberDivOptions: [],
        memberGradeOptions: [],
        sexCodeOptions: [],
        areaCodeOptions: [],
        hopeCourseOptions: [],
        hopeHoleDivOptions: [],
        rceptStatusOptions: [],
      },
      numberTemplate: function () {
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      smsSendFlag: true,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isWaitingReservationPopupOpen ||
        this.isBlacklistPopupOpen ||
        this.isReservationInfoPopupOpen
      );
    },
    shortcutMenuItems() {
      return [
        {
          text: "바로가기 01",
        },
        {
          text: "바로가기 02",
        },
        {
          text: "바로가기 03",
        },
      ];
    },
    etcMenuItems() {
      return [
        {
          text: "다운로드",
        },
        {
          text: "인쇄하기",
        },
      ];
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.waitingReservationSearchOption.waitingDateFrom,
          to: this.waitingReservationSearchOption.waitingDateTo,
        };
      },
      set: function (dateRange) {
        this.waitingReservationSearchOption.waitingDateFrom = dateRange.from;
        this.waitingReservationSearchOption.waitingDateTo = dateRange.to;
      },
    },
    waitingReservationGridOptions() {
      return {
        provides: [Aggregate, Edit, VirtualScroll, Resize, ForeignKey, ExcelExport, Page],
        editSettings: {
          allowGrouping: true,
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        selectionSettings: {
          persistSelection: false,
          type: "Single",
          checkboxOnly: false,
          checkboxMode: "ResetOnRowClick",
          enableToggle: false
        },
        columns: [
          {
            field: "check",
            allowEditing: false,
            allowFiltering: false,
            type: "checkbox",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: "waitingId",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "waitingDateGroup",
            visible: false,
          },
          {
            field: "waitingDate",
            headerText: "대기일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "dwName",
            headerText: "요일",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "rank",
            headerText: "대기순번",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "memberName",
            headerText: "회원명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "contactTel",
            headerText: "연락처",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "left",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            headerText: "예약횟수",
            width: 140,
            columns: [
              {
                field: "weekdayCount",
                headerText: "주중",
                width: 70,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              },
              {
                field: "weekendCount",
                headerText: "주말",
                width: 70,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              }
            ]
          },
          {
            field: "resveInfo",
            headerText: "예약상세",
            width: 210,
            type: "string",
            allowEditing: false
          },
          {
            headerText: "위약정보",
            columns: [
              {
                field: "penltyCount",
                headerText: "횟수",
                width: 90,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              },
              {
                field: "penltyDayCount",
                headerText: "제재일수",
                width: 90,
                type: "number",
                format: "N",
                textAlign: "center",
                allowEditing: false
              }
            ]
          },
          {
            field: "rceptStatus",
            headerText: "접수상태",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.waitingReservationSearchOption.rceptStatusOptions,
          },
          {
            field: "hopeTime1",
            headerText: "희망시간1",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            editType: "dropdownedit",
            dataSource: this.waitingReservationSearchOption.hopeTimeOptions,
          },
          {
            field: "hopeTime2",
            headerText: "희망시간2",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            editType: "dropdownedit",
            dataSource: this.waitingReservationSearchOption.hopeTimeOptions,
          },
          {
            field: "requstContents",
            headerText: "요청내용",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "memoContents",
            headerText: "메모",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "insertName",
            headerText: "등록자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 140,
            textAlign: "left",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveDayOfTheWeekKorean",
            headerText: "요일",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.waitingReservationSearchOption.resveCourseOptions,
          }
        ],
        aggregates: [
          {
            columns: [
              {
                field: "waitingDate",
                aggregationType: "GroupCaption",
                customAggregate: "소계",
              },
              {
                field: "dwName",
                aggregationType: "GroupCount",
                stringFormat: "${value} 건",
              },
            ],
          },
          {
            columns: [
              {
                field: "waitingDate",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "dwName",
                aggregationType: "TotalCount",
                stringFormat: "${value} 건",
              },
            ],
          },
        ],
        groupSettings: { showDropArea: false, columns: ["waitingDateGroup"] },
      };
    },
    timeListGridOptions() {
      return {
        provides: [Edit, VirtualScroll, Resize, ForeignKey],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.timeListOption.resveCourseOptions,
          },
          {
            field: "resveStatus",
            headerText: "예약자명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            textAlign: "left",
          },
        ],
      };
    },
  },
  created: function () {
    const queryResveDate = this.$route.query.resveDate;

    if (
      queryResveDate &&
      moment(queryResveDate, "YYYY-MM-DD", true).isValid()
    ) {
      this.waitingReservationSearchOption.waitingDateFrom = queryResveDate;
      this.waitingReservationSearchOption.waitingDateTo = queryResveDate;
    } else {
      this.waitingReservationSearchOption.waitingDateFrom = getFormattedDate(
        moment().format()
      );
      this.waitingReservationSearchOption.waitingDateTo = getFormattedDate(
        moment().add(1, "month").format()
      );
    }

    this.reservationWaits.waitingDate = getFormattedDate(new Date());

    this.waitingReservationSearchOption.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE",
      true
    );
    if (
      this.waitingReservationSearchOption.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.waitingReservationSearchOption.bsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.waitingReservationSearchOption.bsnCode = "ALL";

    this.waitingReservationSearchOption.rceptStatusOptions = commonCodesGetCommonCode(
      "RCEPT_STATUS",
      true
    );

    this.waitingReservationSearchOption.rceptStatus = ["RCEPT"];

    this.waitingReservationSearchOption.hopeCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.waitingReservationSearchOption.resveCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.waitingReservationSearchOption.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    this.timeListOption.resveCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.timeListOption.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    this.timeListOption.memberGradeOptions = commonCodesGetCommonCode(
      "MEMBER_GRADE"
    );
    this.timeListOption.sexCodeOptions = commonCodesGetCommonCode("SEX_CODE");
    this.timeListOption.areaCodeOptions = commonCodesGetCommonCode("AREA_CODE");
    this.timeListOption.hopeCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.timeListOption.hopeHoleDivOptions = commonCodesGetCommonCode(
      "HOLE_DIV"
    );
    this.timeListOption.rceptStatusOptions = commonCodesGetCommonCode(
      "RCEPT_STATUS"
    );
    this.waitingReservationSearchOption.hopeTimeOptions = commonCodesGetCommonCode(
        "HOPE_TIME_ZONE"
    );

    this.onViewButtonClicked();
  },
  mounted: function () {},
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  methods: {
    numberWithCommas,
    gridUtilGetTelColumnAccess,
    commonCodesGetColorValue,
    onWaitingReservationPopupEvent(eventId, popupKey) {
      if (eventId === "popupClosed") {
        this.isWaitingReservationPopupOpen = false;
        this.reSelectionWaitingId = popupKey;
        this.onViewButtonClicked();
      }
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onBlacklistPopupClose() {
      this.isBlacklistPopupOpen = false;
    },
    onReservationInfoPopupClose() {
      this.isReservationInfoPopupOpen = false;
    },
    waitingReservationInfoPopupOpen() {
      this.reservationWaits.waitingId = null;
      this.isWaitingReservationPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.waitingReservationPopup.showPopup();
      });
    },
    timeListInit() {
      this.timeList = [];
      this.timeCount = {
          resveDate: "",
          timeCount: 0,
          resvePossibleTime: 0,
          dwCode: "",
          dwName: "",
      };
    },
    onViewButtonClicked() {
      this.getWaitingReservation();
      this.timeListInit();
    },
    onSearchDetailViewButtonClick() {
      this.onViewButtonClicked();
    },
    onDialogWaitingReservationOptionInitClicked() {
      this.waitingReservationSearchOption.bsnCode = "ALL";
      this.waitingReservationSearchOption.waitingDateFrom = getFormattedDate(
        new Date()
      );
      this.waitingReservationSearchOption.waitingDateTo = getFormattedDate(
        new Date()
      );
      this.waitingReservationSearchOption.rceptStatus = ["RCEPT", "RESVE"];
      this.waitingReservationSearchOption.memberName = null;
      this.waitingReservationSearchOption.memberNumber = null;
    },
    waitingReservationHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
      if (args.cell.column.field === "resveStatus") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    waitingReservationQueryCellInfo(args) {
      const {
        column: { field },
        cell,
        data,
      } = args;
      if (data.rceptStatus === "RCEPT_CANCEL") {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (field === "rceptStatus") {
        cell.style.color = commonCodesGetColorValue(
          "RCEPT_STATUS",
          args.data.rceptStatus
        );
      }
      if (field === "check") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if(field === "memberName" && data.penltyCount) {
        cell.style.background = "#ff0000";
      }
      if (
        (field === "weekdayCount" && data.weekdayCount < 1) ||
        (field === "weekendCount" && data.weekendCount < 1) ||
        (field === "penltyCount" && data.penltyCount < 1) ||
        (field === "penltyDayCount" && data.penltyDayCount < 1)
      ) {
        cell.innerText = "-";
      }
      if (
        field === "memberName" ||
        field === "memberNo" ||
        (field === "resveInfo" && data.resveInfo) ||
        (field === "penltyCount" && data.penltyCount) ||
        (field === "penltyDayCount" && data.penltyDayCount)
      ) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "dwName") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      }
    },
    timeListQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data
      } = args;
      if (field === undefined) {
        if (data.agncyOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "AGNCY_OPEN_FLAG"
          );
        } else if (data.webOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
          "COLOR_TIME_STATUS",
          "WEB_OPEN_FLAG"
          );
        }
      } else if (field === "resveTime") {
        cell.classList.add(this.$t("className.grid.selectedCellBold"));
        if (
          data.timeStatus === "BLANK" ||
          data.timeStatus === "BLOCK" ||
          data.timeStatus === "HOLD"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "TIME_STATUS",
            data.timeStatus
          );
          cell.style.color = "#FFFFFF";
        } else if (
          data.cancelDiv === "RAIN" ||
          data.cancelDiv === "NOSHOW"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "CANCEL_DIV",
            data.cancelDiv
          );
          cell.style.color = "#FFFFFF";
        }
      } else if (field === "resveStatus") {
        cell.classList.add(this.$t("className.grid.clickArea"));
        if (
          !!data.resveId &&
          data.webOpenFlag
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "WEB_OPEN_FLAG"
          );
        } else if (
          !!data.grpKind &&
          data.grpKind !== "FIT"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "GRP_KIND",
            data.grpKind
          );
        } else if (
          data.memberDiv !== null &&
          data.memberDiv !== undefined
        ) {
          // MEMBER_DIV 의 3번째 index Attribute 로 COLOR_MEMBER_DIV의 colorValue 조회 후 백그라운드 등록
          const attrMemberDiv = commonCodesGetCommonCodeAttrbByCodeAndIdx(
            "MEMBER_DIV",
            data.memberDiv,
            3
          );
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_MEMBER_DIV",
            attrMemberDiv
          );
          cell.style.color = commonCodesGetColorValue(
            "MEMBER_DIV",
            data.memberDiv
          );
        }
      }
    },
    async waitingReservationGridRecordClicked(args) {
      const {
        column: { field },
        rowData: data,
      } = args;
      if (args.column) {
        if (field === "memberName" || field === "memberNo") {
          this.reservationWaits.waitingId = data.waitingId;
          this.isWaitingReservationPopupOpen = true;
          await this.$nextTick();
          this.$refs.waitingReservationPopup.showPopup(data.waitingId);
        }
        if (field === "resveInfo") {
          if (data.resveInfo) {
            this.viewReservationInfo(data);
          }
        }
        if (
          (field === "penltyCount" && data.penltyCount > 0) ||
          (field === "penltyDayCount" && data.penltyDayCount > 0)
        ) {
          this.isBlacklistPopupOpen = true;
          await this.$nextTick();
          await this.$refs.blacklistPopup.showBlackListPopup({
            waitingReservationInfo: data,
          }, true);
        }
      }
    },
    viewReservationInfo(data) {
      console.log('data.===>', data);
      this.isReservationInfoPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationInfoPopup.showReservationInfoPopup(
          moment(data.waitingDate).format("YYYY-MM-01"),
          moment(data.waitingDate).endOf("month").format("YYYY-MM-DD"),
          null,
          data.memberNo
        );
      });
    },
    waitingReservationGridRowSelected(args) {
      this.getReservationWaitTimeList(args.data);
    },
    async timeListGridRecordClicked(args) {
      if (args.column && args.column.field === "resveStatus") {
        if (
          args.rowData.timeStatus === "EMPTY" &&
          !args.rowData.webOpenFlag
        ) {
          const today = await getTodayNavigationDate("YYYY-MM-DD");
          const timeData = args.rowData;
          const getRecords = this.$refs.waitingReservationGrid.getSelectedRecords();

          if (!(getRecords.length > 0)) {
            this.errorToast("예약확정할 대기예약을 선택해 주시기 바랍니다.");
            return;
          }

          if (getRecords[0].rceptStatus === "RCEPT") {
            // 예약가능 클릭시 해당 타임 홀딩 및 타임 상태 확인을 위함.
            const data = await GolfErpAPI.fetchReservationTimesDetail({
              timeId: timeData.timeId,
            });

            if (data.status !== "OK") {
              if (data.status === "HOLD_TIME_EXIST") {
                this.errorToast("홀딩된 시간입니다.");
              } else if (data.status === "WEB_TIME_EXIST") {
                this.errorToast("웹타임 설정된 시간입니다.");
              }
              return;
            }

            if (
              !(await this.confirm(
                "예약일자 : " +
                  args.rowData.resveDate +
                  "<br>" +
                  "예약시간 : " +
                  args.rowData.resveTime +
                  " " +
                  commonCodesGetComName(
                    "COURSE_CODE",
                    args.rowData.resveCourse
                  ) +
                  " 코스<br>" +
                  "예약자 : " +
                  getRecords[0].memberName +
                  "<br><br>" +
                  '"예약확정" 하시겠습니까?'
              ))
            ) {
              // 취소시 해당 타임 홀딩 취소.
              await GolfErpAPI.reservationTimesHoldCancel([timeData.timeId]);
              return;
            }

            const saveData = {
              reservationTimes: [
                {
                  timeId: timeData.timeId,
                  optFlag: timeData.optFlag,
                  timeStatus: timeData.timeStatus,
                  resveDate: timeData.resveDate,
                  resveNo: null,
                  reservationConfirm: {
                    resveId: null,
                    optFlag: null,
                    timeId: timeData.timeId,
                    resveNo: null,
                    visitCnt: 4,
                    promtnId: data?.data?.reservationDetails[0]?.promtnId || null,
                    teamGreenFee: 0,
                    selfFlag: false,
                    joinFlag: false,
                    contactTel: getRecords[0].contactTel,
                    reservationCmpns: []
                  },
                },
              ],
              reservationMember: {
                resveNo: null,
                optFlag: null,
                resveKind: today === timeData.resveDate ? "DAY" : commonCodeGetComCodeDefaultValue("RESVE_KIND"),
                resveChannel: commonCodeGetComCodeDefaultValue("RESVE_CHANNEL"),
                resveName: getRecords[0].memberName,
                membershipId: getRecords[0].membershipId,
                memberNo: getRecords[0].memberNo,
                memberDiv: getRecords[0].memberDiv,
                memberGrade: getRecords[0].memberGrade,
                sexCode: getRecords[0].sexCode,
                areaCode: getRecords[0].areaCode,
                contactName: getRecords[0].memberName,
                contactTel: getRecords[0].contactTel,
                grpNo: null,
                grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
                grpName: null,
                resveRemarks: null,
                recomendr: null,
                vipFlag: false
              },
              waitingId: getRecords[0].waitingId,
            };

            // 무인체크인 사용시 예약자를 동반자정보에 셋팅.
            if (commonCodesGetStandardInfo("noneChkinUseFlag")) {
              saveData.reservationTimes[0].reservationConfirm.reservationCmpns.push({
                sno: 1,
                reprsntFlag: true,
                cmpnName: getRecords[0].memberName,
                joinGroupNo: null,
                contactTel: getRecords[0].contactTel,
                sexCode: getRecords[0].sexCode,
                lockerNo: null,
                memberNo: getRecords[0].memberNo,
                membershipId: getRecords[0].membershipId,
                memberDiv: getRecords[0].memberDiv,
                memberGrade: getRecords[0].memberGrade,
              });
            }

            const newResveNo = await GolfErpAPI.reservationSave(saveData);

            if (this.smsSendFlag) {
              const smsSendData = {
                resveNo: newResveNo.resveNo,
                resveDate: timeData.resveDate,
                resveCourse: timeData.resveCourse,
                resveTime: timeData.resveTime,
                smsRecptnDiv: getRecords[0].smsRecptnDiv,
                resveMngrHp: getRecords[0].resveMngrHp,
                contactName: getRecords[0].memberName,
                memberDiv: getRecords[0].memberDiv,
                contactTel: getRecords[0].contactTel,
                bsnCode: timeData.bsnCode,
                selfOpenFlag: timeData.selfOpenFlag,
              };

              this.autoSendSms(smsSendData);
            }
            this.onViewButtonClicked();
          } else {
            this.errorToast("접수등록만 예약확정이 가능합니다");
          }
        }
      }
    },
    async waitingReservationCancelButtonClicked() {
      const data = this.$refs.waitingReservationGrid.getSelectedRecords();

      if (data.length === 0) {
        this.errorToast("데이터를 선택하여 주시기 바랍니다");
        return;
      }

      let notRceptCnt = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].rceptStatus !== "RCEPT") {
          notRceptCnt++;
        }
      }

      if (notRceptCnt > 0) {
        this.errorToast(
          '접수상태가 "접수등록"인 데이터만 접수취소가 가능합니다'
        );
        return;
      }

      if (
        !(await this.confirm('선택한 대기예약정보를 "접수취소" 하시겠습니까?'))
      ) {
        return;
      }

      saveReservationWaitCancel(data)
        .then(() => {
          this.onViewButtonClicked();
        })
        .catch((error) => {
          console.log("saveReservationWaitCancel.err.===>", error);
        });
    },
    dataBoundEvent() {
      if (this.waitingReservationList.length > 0) {
        if (this.reSelectionWaitingId !== null) {
          const row = this.$refs.waitingReservationGrid.getRowIndexByPrimaryKey(
            this.reSelectionWaitingId
          );
          if (row >= 0) {
            this.$refs.waitingReservationGrid.selectRow(row);
          } else {
            this.$refs.waitingReservationGrid.selectRow(0);
          }
          this.reSelectionWaitingId = null;
        } else {
          this.$refs.waitingReservationGrid.selectRow(0);
        }
      }
    },
    getWaitingReservation(waitingId) {
      const bsnCode =
        this.waitingReservationSearchOption.bsnCode === "ALL"
          ? null
          : this.waitingReservationSearchOption.bsnCode;
      const memberName =
        this.waitingReservationSearchOption.memberName === ""
          ? null
          : this.waitingReservationSearchOption.memberName;
      const memberNameNumber =
        this.waitingReservationSearchOption.memberNameNumber === ""
          ? null
          : this.waitingReservationSearchOption.memberNameNumber;
      const memberNumber =
        this.waitingReservationSearchOption.memberNumber === ""
          ? null
          : this.waitingReservationSearchOption.memberNumber;
      const rceptStatusList =
        this.waitingReservationSearchOption.rceptStatus?.length < 1 ||
        this.waitingReservationSearchOption.rceptStatus?.length ===
          commonCodesGetCommonCode("RCEPT_STATUS", true)?.length
          ? null
          : this.waitingReservationSearchOption.rceptStatus.toString();
      const waitingDateFrom = this.waitingReservationSearchOption
        .waitingDateFrom;
      const waitingDateTo = this.waitingReservationSearchOption.waitingDateTo;
      getWaitingReservation(
        waitingId,
        bsnCode,
        memberName,
        memberNameNumber,
        memberNumber,
        rceptStatusList,
        waitingDateFrom,
        waitingDateTo
      )
        .then((response) => {
          this.waitingReservationList = response.value.reservationWaits
            ?.map(item => {
              item.waitingDateGroup = item.waitingDate;
              return item;
            });
          this.waitingReservationListLength =
            response.value.reservationWaits.length;
        })
        .catch((error) => {
          console.log("getTimeTable.err.===>", error);
        });
    },
    getReservationWaitTimeList(data) {
      const resveDate = data.waitingDate;
      const dwCode = data.dwCode;
      const dwName = data.dwName;
      getReservationWaitTimeList(resveDate)
        .then((response) => {
          this.timeList = _orderBy(response.value.reservationWaitTimeLists.map(item => ({
            ...item,
            courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),
          })), ["resveDate", "resveTime", "courseCodeSortNo"]);

          this.timeCount.resvePossibleTime = this.timeList.filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;
          this.timeCount.resveDate = resveDate;
          this.timeCount.dwCode = dwCode;
          this.timeCount.dwName = dwName;
          // if (response.value.reservationPossibleTimeCounts.length > 0) {
          //   this.timeCount = response.value.reservationPossibleTimeCounts;
          // } else {
          //   this.timeCount[0].resveDate = resveDate;
          //   this.timeCount[0].timeCount = 0;
          //   this.timeCount[0].resvePossibleTime = 0;
          //   this.timeCount[0].dwCode = dwCode;
          //   this.timeCount[0].dwName = dwName;
          // }
        })
        .catch((error) => {
          console.log("getReservationWaitTimeList.err.===>", error);
        });
    },
    waitingReservationGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.waitingReservationGrid?.getGridBatchCount() || 0
      );
    },
    async autoSendSms(data) {
      let smsSendList = [];
      let sendData = {};

      sendData.sendKey = data.resveNo;
      sendData.resveDate = data.resveDate;
      sendData.dwName = getDayOfWeekCaption(moment(data.resveDate).toDate());
      sendData.resveCourse = data.resveCourse;
      sendData.resveTime = data.resveTime;
      sendData.transFlag = false;
      sendData.bsnCode = data.bsnCode;
      sendData.caddieSelfDiv = data.selfOpenFlag ? "셀프" : "동반";

      sendData.resveCourseTime =
        commonCodesGetComName("COURSE_CODE", data.resveCourse) +
        " " +
        data.resveTime +
        " (1팀)";

      if (data.smsRecptnDiv === "B") {
        if (data.resveMngrHp) {
          sendData.receiverName = data.contactName;
          sendData.name =
            data.contactName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              data.memberDiv,
              1
            );
          sendData.contactTel = data.resveMngrHp;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else if (data.smsRecptnDiv === "A") {
        sendData.receiverName = data.contactName;
        sendData.name =
          data.contactName +
          " " +
          commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
            "MEMBER_DIV",
            data.memberDiv,
            1
          );

        if (data.contactTel) {
          sendData.contactTel = data.contactTel;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }

        if (data.resveMngrHp) {
          sendData.contactTel = data.resveMngrHp;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else {
        if (data.contactTel) {
          sendData.receiverName = data.contactName;
          sendData.name =
            data.contactName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              data.memberDiv,
              1
            );
          sendData.contactTel = data.contactTel;
          sendData.smsSendFlag = true;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      }

      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: "G_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };
      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          JSON.stringify(message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
          JSON.parse(JSON.stringify(message).replace("[HEADER_MEMO]$\\n", "")) :
          JSON.parse(JSON.stringify(message).replace("[HEADER_MEMO]$", ""));
        message = message + "\n[HEADER_MEMO]$";
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        if (data.smsSendFlag) {
          const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
          const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
          const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
          const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");

          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoDispatchKey
              : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoTemplateCode
              : null,
            key: data.sendKey,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.receiverName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            message: message,
            replaceDataMap: {
              name: data.name,
              resveDate: data.resveDate,
              dwName: data.dwName,
              resveCourse: data.resveCourse,
              resveTime: `[${data.resveTime}]`,
              resveCourseTime: data.resveCourseTime,
              transResveDiv: "예약",
              cancelDate: moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD"),
              cancelTime: data.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime,
              cancelDw: getDayOfWeekCaption(moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD")),
              headerMemo: smsSendInfo.bizName,
              transInfo: "",
              caddieSelfDiv: data.caddieSelfDiv,
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
          };

          smsRequestList.push(smsInfo);
        }
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    async onTimeListGridActionComplete(args) {
      if (args.requestType === "refresh") {
        this.$refs.timeListGrid.filterByColumn(
          "resveStatus",
          "equal",
          "예약가능"
        );
        await this.$nextTick();
        const $el = document.querySelector("#confirmDialogOkay");
        if ($el) {
          args.cancel = true;
          setTimeout(function () {
            document.activeElement.blur();
            $el.focus();
          });
        }
      }

      this.timeCount.resvePossibleTime = this.$refs.timeListGrid.getCurrentViewRecords().filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;
    },
    onClickExcel() {
      this.$refs.waitingReservationGrid.excelExport();
    },
  },
};
</script>
